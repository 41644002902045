import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./pages/application_pages/application.module').then( m => m.ApplicationModule), 
    // canLoad: [AuthGuardsService] 
  },
  { 
    path: 'auth', 
    loadChildren: () => import('./pages/authentication_pages/auth.module').then( m => m.AuthModule), 
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
