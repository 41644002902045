
  <!-- ========== HEADER ========== -->
  <header id="header" class="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light">
    <div class="container">
      <nav class="js-mega-menu navbar-nav-wrap">
        <!-- Default Logo -->
        <a class="navbar-brand" routerLink="/" aria-label="Aide Logo">
          <img style="width: 45px;margin-top: -10px;" src="assets/icons/logo_blue.png" alt="Aide Logo">
          <span class="text-primary fs-22">Aide Finance</span>
        </a>
        <!-- End Default Logo -->

        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-default">
            <i class="bi-list"></i>
          </span>
          <span class="navbar-toggler-toggled">
            <i class="bi-x"></i>
          </span>
        </button>
        <!-- End Toggler -->

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <div class="navbar-absolute-top-scroller">
            <ul class="navbar-nav">

              <li class="hs-has-sub-menu nav-item">
                <a class="hs-mega-menu-invoker nav-link" routerLink="/" role="button" aria-expanded="false">Home</a>
              </li>

              <li class="hs-has-sub-menu nav-item">
                <a class="hs-mega-menu-invoker nav-link" routerLink="/features" role="button" aria-expanded="false">Features</a>
              </li>

              <li class="hs-has-sub-menu nav-item">
                <a class="hs-mega-menu-invoker nav-link" routerLink="/about-us" role="button" aria-expanded="false">About Us</a>
              </li>

              <li class="hs-has-sub-menu nav-item">
                <a class="hs-mega-menu-invoker nav-link" routerLink="/contact-us" role="button" aria-expanded="false">Contact Us</a>
              </li>

              <li class="nav-divider"></li>

              <!-- Log in -->
              <li class="nav-item">
                <a class="js-animation-link btn btn-ghost-secondary btn-no-focus me-2 me-lg-0" href="https://app.aidefinance.io/" target="_blank">Log in</a>

                <a class="js-animation-link d-lg-none btn btn-primary" href="https://app.aidefinance.io/auth/register" target="_blank">
                  <i class="bi-person-circle me-1"></i> Sign up
                </a>
              </li>
              <!-- End Log in -->

              <!-- Sign up -->
              <li class="nav-item">
                <a class="js-animation-link d-none d-lg-inline-block btn btn-primary" href="https://app.aidefinance.io/auth/register" target="_blank">
                  Sign up
                </a>
              </li>
              <!-- End Sign up -->

              <!-- Sign up -->
              <li class="nav-item">
                <a target="_blank" href="https://partners.aidefinance.io/" class="js-animation-link d-none d-lg-inline-block btn btn-primary">
                  Become a Partner
                </a>
              </li>
              <!-- End Sign up -->
            </ul>
          </div>
        </div>
        <!-- End Collapse -->
      </nav>
    </div>
  </header>
  <!-- ========== END HEADER ========== -->

