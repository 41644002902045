import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { StorageServices } from './storage-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { ContactForm } from '../../interfaces/interfaces';

declare var $ :any;

@Injectable({
  providedIn: 'root'
})
export class GlobalsService 
{
  showsplash: boolean = false;
  loading: boolean = false;
  url:string;
  title:string = 'overview';

  constructor(
    public spinner: NgxSpinnerService,
    public router: Router, 
    private location: Location, 
    public storage: StorageServices,
  ) {
    this.url = this.location.path();
  }

  navigate(path: string, subpage: boolean = true) {
    this.router.navigate([path], { replaceUrl: subpage });
  }

  async sendContactMail(form: ContactForm): Promise<any> {
  try {
    const response = await fetch('https://apis.aidefinance.io/sendmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(form)
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error:', error);
  }
}

}
