
<div class="splash" *ngIf="globals.showsplash">
  <div align="center" class="animated fadeIn">
    <img src="assets/icon/favicon.png" class="animated pulse infinite" />
  </div>
  <br />
</div>


<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="default" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<router-outlet></router-outlet>


<a class="js-go-to go-to position-fixed" href="javascript:;" style="visibility: hidden;" data-hs-go-to-options='{
  "offsetTop": 700,
  "position": {
    "init": {
      "right": "2rem"
    },
    "show": {
      "bottom": "2rem"
    },
    "hide": {
      "bottom": "-2rem"
    }
  }
}'>
<i class="bi-chevron-up"></i>
</a>