<footer class="bg-dark border-top border-white-10">
    <div class="container">
      <div class="row content-space-1">
        <div class="col-lg-3 mb-7 mb-lg-0">
          <!-- Logo -->
          <div class="mb-5">
            <a class="navbar-brand" routerlink="/" aria-label="Aide Logo">
                <img style="width: 40px;margin-top: -10px;" src="assets/icons/logo.png" alt="Aide Logo">
                <span class="text-white fs-22">Aide Finance</span>
            </a>
          </div>
          <!-- End Logo -->

          <span class="d-block">
            <label for="selectLanguage" class="form-label text-white">Choose language</label>
          </span>

          <!-- Button Group -->
          <div class="btn-group">
            <button type="button" class="btn btn-soft-light btn-sm dropdown-toggle" id="selectLanguage" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="Image description" width="16">
                <span>English (US)</span>
              </span>
            </button>

            <div class="dropdown-menu">
              <a class="dropdown-item d-flex align-items-center active" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/us.svg" alt="Image description" width="16">
                <span>English (US)</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/gb.svg" alt="Image description" width="16">
                <span>English (UK)</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/de.svg" alt="Image description" width="16">
                <span>Deutsch</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/dk.svg" alt="Image description" width="16">
                <span>Dansk</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/es.svg" alt="Image description" width="16">
                <span>Español</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/nl.svg" alt="Image description" width="16">
                <span>Nederlands</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/it.svg" alt="Image description" width="16">
                <span>Italiano</span>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <img class="avatar avatar-xss avatar-circle me-2" src="assets/vendor/flag-icon-css/flags/1x1/cn.svg" alt="Image description" width="16">
                <span>中文 (繁體)</span>
              </a>
            </div>
          </div>
          <!-- End Button Group -->
        </div>
        <!-- End Col -->

        <div class="col-sm mb-7 mb-sm-0">
          <span class="text-cap text-primary-light">Resources</span>

          <!-- List -->
          <ul class="list-unstyled list-py-1 mb-0">
            <li><a class="link-sm link-light" href="#">Blog</a></li>
            <li><a class="link-sm link-light" href="#">Guidance</a></li>
            <li><a class="link-sm link-light" href="#">Customer Stories</a></li>
            <li><a class="link-sm link-light" href="#">Support <i class="bi-box-arrow-up-right ms-1"></i></a></li>
            <li><a class="link-sm link-light" href="#">API</a></li>
            <li><a class="link-sm link-light" href="#">Packages</a></li>
          </ul>
          <!-- End List -->
        </div>
        <!-- End Col -->

        <div class="col-sm mb-7 mb-sm-0">
          <span class="text-cap text-primary-light">Company</span>

          <!-- List -->
          <ul class="list-unstyled list-py-1 mb-0">
            <li><a class="link-sm link-light" href="#">Belonging <i class="bi-box-arrow-up-right ms-1"></i></a></li>
            <li><a class="link-sm link-light" href="#">Company</a></li>
            <li><a class="link-sm link-light" href="#">Careers <span class="badge bg-warning text-dark rounded-pill ms-2">We're hiring</span></a></li>
            <li><a class="link-sm link-light" href="#">Contacts</a></li>
            <li><a class="link-sm link-light" href="#">Security</a></li>
          </ul>
          <!-- End List -->
        </div>
        <!-- End Col -->

        <div class="col-sm">
          <span class="text-cap text-primary-light">Platform</span>

          <!-- List -->
          <ul class="list-unstyled list-py-1 mb-0">
            <li><a class="link-sm link-light" href="#">Web</a></li>
            <li><a class="link-sm link-light" href="#">Mobile</a></li>
            <li><a class="link-sm link-light" href="#">iOS</a></li>
            <li><a class="link-sm link-light" href="#">Android</a></li>
            <li><a class="link-sm link-light" href="#">Figma Importing</a></li>
          </ul>
          <!-- End List -->
        </div>
        <!-- End Col -->

        <div class="col-sm">
          <span class="text-cap text-primary-light">Legal</span>

          <!-- List -->
          <ul class="list-unstyled list-py-1 mb-5">
            <li><a class="link-sm link-light" href="#">Terms of use</a></li>
            <li><a class="link-sm link-light" href="#">Privacy policy <i class="bi-box-arrow-up-right ms-1"></i></a></li>
          </ul>
          <!-- End List -->

          <span class="text-cap text-primary-light">Docs</span>

          <!-- List -->
          <ul class="list-unstyled list-py-1 mb-0">
            <li><a class="link-sm link-light" href="#">Documentation</a></li>
            <li><a class="link-sm link-light" href="#">Snippets</a></li>
          </ul>
          <!-- End List -->
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->

      <div class="border-top border-white-10"></div>

      <div class="row align-items-md-end py-5">
        <div class="col-md mb-3 mb-md-0">
          <p class="text-white mb-0">©. 2023 Aide Finance. All rights reserved.</p>
        </div>

        <div class="col-md d-md-flex justify-content-md-end">
          <!-- Socials -->
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a class="btn btn-icon btn-sm btn-soft-light rounded-circle" href="#">
                <i class="bi-facebook"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-icon btn-sm btn-soft-light rounded-circle" href="#">
                <i class="bi-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-icon btn-sm btn-soft-light rounded-circle" href="#">
                <i class="bi-github"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="btn btn-icon btn-sm btn-soft-light rounded-circle" href="#">
                <i class="bi-slack"></i>
              </a>
            </li>
          </ul>
          <!-- End Socials -->
        </div>
      </div>
    </div>
  </footer>